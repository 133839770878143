<template>
  <div class="dates">
    <!-- <a href="https://www.songkick.com/artists/9601444" class="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="true" data-font-color="#ffffff" data-background-color="transparent" data-locale="en">Indecent Behavior tour dates</a>
    <component :is="'script'" src="//widget.songkick.com/9601444/widget.js" async></component>
     -->
    <div class="date" v-for="(date, i) in dates" :key="i">
      <div style="font-size: 12px; margin-bottom: 8px">
        {{date.day}} {{date.date}} <span style="opacity: 0.5" v-if="date.time !== ''">• {{date.time}}</span>
      </div>
      <div style="font-size: 18px; margin-bottom: 4px">
        {{ date.city }}
      </div>
      <div style="font-size: 14px;">
        {{ date.club }}
      </div>
      <v-btn v-if="$vuetify.breakpoint.smAndUp && !date.soldout" @click="navigate(date.tickets)" style="position: absolute; right: 30px; top: 35px; text-transform: none; padding: 0 40px">
        Tickets
      </v-btn>
      <v-btn v-if="$vuetify.breakpoint.xs && !date.soldout" @click="navigate(date.tickets)" style="text-transform: none; width: 100%; margin-top: 20px">
        Tickets
      </v-btn>
      <span v-if="$vuetify.breakpoint.smAndUp && date.soldout" style="font-size: 20px; font-weight: 600; position: absolute; right: 8px; top: 45px; text-transform: none; padding: 0 40px; color: red">
        SOLD OUT
      </span>
      <div v-if="$vuetify.breakpoint.xs && date.soldout" style="font-size: 20px; font-weight: 600; text-transform: none; width: 100%; margin-top: 10px; color: red; text-align: center">
        SOLD OUT
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dates: [
      {
        day: 'SAT',
        date: '23 NOVEMBER',
        time: '7:00 PM',
        city: 'Stuttgart, Germany',
        club: 'Im Wizemann [Zebrahead Support]',
        tickets: 'https://www.eventim.de/event/zebrahead-im-wizemann-stuttgart-18966221/?affiliate=TUG'
      },
      {
        day: 'WED',
        date: '11 DECEMBER',
        time: '7:00 PM',
        city: 'Nuremberg, Germany',
        club: 'Hirsch [Zebrahead Support]',
        tickets: 'https://www.eventim.de/event/zebrahead-hirsch-18483012/?affiliate=TUG'
      },
      {
        day: 'SAT',
        date: '14 DECEMBER',
        time: '7:00 PM',
        city: 'Cologne, Germany',
        club: 'Stollwerk [Zebrahead Support]',
        tickets: 'https://www.eventim.de/event/zebrahead-buergerhaus-stollwerck-18969198/?affiliate=TUG'
      },
      {
        day: 'SUN',
        date: '15 DECEMBER',
        time: '7:00 PM',
        city: 'Hamburg, Germany',
        club: 'Markthalle [Zebrahead Support]',
        tickets: 'https://www.eventim.de/event/zebrahead-markthalle-hamburg-18982223/?affiliate=TUG'
      },
      {
        day: 'FRI',
        date: '20 DECEMBER',
        time: '6:30 PM',
        city: 'Vechta, Germany',
        club: 'Gulfhaus',
        tickets: 'https://www.eventim.de/artist/indecent-behavior/?srsltid=AfmBOorUjOQRBqVaOBGPXTBKCaysEqNvmzxyPRFS-0rxiqLt6Mdnf86j'
      },
      {
        day: 'SAT',
        date: '21 DECEMBER',
        time: '7:00 PM',
        city: 'Saarbrücken, Germany',
        club: 'Kleiner Klub',
        tickets: 'https://www.eventim.de/artist/indecent-behavior/?srsltid=AfmBOorUjOQRBqVaOBGPXTBKCaysEqNvmzxyPRFS-0rxiqLt6Mdnf86j',
        soldout: true
      },
      {
        day: 'SAT',
        date: '28 DECEMBER',
        time: '7:00 PM',
        city: 'Osnabrück, Germany',
        club: 'Rosenhof [Donots JAK Support]',
        tickets: 'https://www.donots.com/#newsletter',
        soldout: false
      },
      {
        day: 'WED - SAT',
        date: '28 JUNE 2025',
        time: '',
        city: 'MOOS MEADOW FESTIVAL',
        club: 'Feuchtwangen, Germany',
        tickets: 'https://meadowfestival.reservix.de/events',
        soldout: false
      },
      {
        day: 'WED - SAT',
        date: '06 - 10 AUGUST 2025',
        time: '',
        city: 'OPEN FLAIR FESTIVAL',
        club: 'Eschwege, Germany',
        tickets: 'https://shop.open-flair.de/produkte/376-tickets-open-flair-festival-2025-eschwege-eschwege-am-06-08-2025',
        soldout: false
      },
      ]
    }
  },
  methods: {
    navigate (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped>
.dates {
  padding: 100px 5%;
  background-image: url('~@/assets/DSC_0698.jpg');
  background-attachment: fixed;
  background-size: cover;
  color: white;
}

.date {
  width: 100%;
  max-width: 1000px;
  background-color: #0000004a;
  padding: 25px;
  text-align: left;
  position: relative;
  margin: 10px auto;
  border-radius: 5px
}
</style>
